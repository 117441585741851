import { useContext, useState } from "react";
import { useQueryClient } from "react-query";

import { Training, TrainingStatusType } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, DateField, TextField, TimeField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { DocsSelectDialog, Select } from "@megaron/dash-select";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";
import { newUuid } from "@megaron/uuid";

import { RegionField } from "../customers/RegionField";

type Props = {
  training?: Training;
  queryKey: string | string[];
  date: Date;
  onClose: () => void;
  isOpen: boolean;
};

export const SaveTraining: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceType();

  const saveTrainingMutation = useClientManager("crm").saveTraining().useMutation();
  const removeTrainingMutation = useClientManager("crm").removeTraining().useMutation();

  const [name, setName] = useState(props.training?.name ?? "");
  const [trainers, setTrainers] = useState(props.training?.trainers ?? []);
  const [participants, setParticipants] = useState(props.training?.participants ?? []);
  const [posList, setPosList] = useState(props.training?.posList ?? []);
  const [region, setRegion] = useState<string | null>(props.training?.region ?? "");
  const [status, setStatus] = useState(props.training?.status ?? "planned");
  const [location, setLocation] = useState(props.training?.location ?? "");
  const [note, setNote] = useState(props.training?.note ?? "");
  const [date, setDate] = useState(props.training?.date ?? props.date);
  const [startTime, setStartTime] = useState(props.training?.startTime ?? new Date(new Date().setHours(9, 0, 0, 0)));
  const [endTime, setEndTime] = useState(props.training?.endTime ?? new Date(new Date().setHours(17, 0, 0, 0)));
  const [error, setError] = useState<string | null>(null);
  const auth = useContext(IamAuthContext);

  const statusOptions = [
    { label: "Planowane szkolenie", value: "planned" },
    { label: "Zakończone", value: "completed" },
    { label: "Odwołane", value: "canceled" },
    { label: "Szkoła GPW", value: "gpw" },
    { label: "Szkolenie w firmie", value: "inCompany" },
  ];

  const isUserAuthorized =
    !props.training ||
    (props.training?.ownerId &&
      (auth.iamUser?.roles.includes("crm.admin") || auth.iamUser?.roles.includes("crm.supervisor"))) ||
    auth.iamUser?.email === props.training?.ownerId;

  const handleSubmit = () => {
    if (!isUserAuthorized) return;

    if (!name) {
      setError("Nazwa jest wymagana.");
      return;
    }

    if (endTime <= startTime) {
      setError("Godzina zakończenia musi być później niż godzina rozpoczęcia.");
      return;
    }

    setError(null);

    const trainingData = {
      trainingId: props.training?.trainingId ?? newUuid(),
      name,
      trainers,
      participants,
      status,
      location,
      note,
      startTime,
      endTime,
      date: date,
      region: region ?? "",
      posList: posList,
    };

    saveTrainingMutation.mutate(trainingData, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(props.queryKey);
        props.onClose();
      },
      onError: (error) => {
        console.error("Error saving training", error);
      },
    });
  };

  const removeTraining = () => {
    if (props.training)
      removeTrainingMutation.mutate(
        { uuid: props.training.trainingId },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(props.queryKey);
            props.onClose();
          },
          onError: (error) => {
            console.error("Error removing training", error);
          },
        },
      );
  };

  const handleEndTimeChange = (newEndTime: Date) => {
    if (newEndTime <= startTime) {
      setError("Godzina zakończenia musi być później niż godzina rozpoczęcia.");
    } else {
      setEndTime(newEndTime);
      setError(null);
    }
  };

  return (
    <Dialog
      onClose={props.onClose}
      header={props.training?.name ?? "Dodaj szkolenie"}
      placement={isMobile ? "top" : "center"}
      css={{ width: "600px", height: isMobile ? "min(100dvh - 4rem, 680px)" : "auto" }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "18px",
          overflowY: "auto",
          maxHeight: "calc(100vh - 150px)",
          padding: "2px",
        }}
      >
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "18px" }}>
          <TextField
            label="Nazwa"
            value={name}
            onChange={(value) => setName(value)}
            isDisabled={!isUserAuthorized}
            autoFocus
            css={{ flex: 1 }}
          />

          <DateField
            value={date}
            onChange={setDate}
            isDisabled={!isUserAuthorized}
            label="data szkolenia"
            css={{ flex: 1 }}
          />
        </div>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "18px" }}>
          <TimeField
            value={startTime}
            onChange={setStartTime}
            isDisabled={!isUserAuthorized}
            label="godzia rozpoczęcia"
            css={{ flex: 1 }}
          />
          <TimeField
            value={endTime}
            onChange={handleEndTimeChange}
            isDisabled={!isUserAuthorized}
            label="godzina zakończenia"
            css={{ flex: 1 }}
          />
          <Select
            label="Status"
            options={statusOptions}
            value={status}
            onChange={(selectedValue: string) => setStatus(selectedValue as TrainingStatusType)}
            isDisabled={!isUserAuthorized}
            css={{ flex: 1 }}
          />
        </div>

        <DocsSelectDialog
          docType={["user"]}
          initiallySelectedDocIds={trainers.map((t) => ResourceId("user", t))}
          variant="multi-select"
          label="Osoby prowadzące"
          onSelectedChange={(options) => {
            const selectedIds = options.map((option) => option.value);
            setTrainers(selectedIds);
          }}
          isDisabled={!isUserAuthorized}
        />
        <DocsSelectDialog
          docType={["user"]}
          initiallySelectedDocIds={participants.map((p) => ResourceId("user", p))}
          variant="multi-select"
          label="Uczestnicy"
          onSelectedChange={(options) => {
            const selectedIds = options.map((option) => option.value);
            setParticipants(selectedIds);
          }}
          isDisabled={!isUserAuthorized}
        />

        <DocsSelectDialog
          docType={["customer"]}
          initiallySelectedDocIds={posList.map((p) => ResourceId("customer", p))}
          variant="multi-select"
          label="Punkty sprzedaży i wykonawcy"
          onSelectedChange={(options) => {
            const selectedIds = options.map((option) => option.value);
            setPosList(selectedIds);
          }}
          isDisabled={!isUserAuthorized}
        />
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "18px" }}>
          <RegionField region={region} onRegionChange={(e) => setRegion(e)} />
          <TextField
            label="Lokalizacja"
            value={location}
            onChange={(value) => setLocation(value)}
            isDisabled={!isUserAuthorized}
            css={{ minWidth: "45%" }}
          />
        </div>

        <TextField
          label="Notatka"
          value={note}
          onChange={(value) => setNote(value)}
          multiline
          isDisabled={!isUserAuthorized}
        />
        {error && <div css={{ color: "red", fontSize: "14px" }}>{error}</div>}
        <div
          css={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "18px",
            marginTop: "auto",
          }}
        >
          {props.training && (
            <Button
              onClick={removeTraining}
              isDisabled={!isUserAuthorized}
              variant={"outline"}
              color="danger"
              isLoading={removeTrainingMutation.isLoading}
            >
              Usuń
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            isDisabled={!isUserAuthorized}
            isLoading={saveTrainingMutation.isLoading}
            spinnerColor={"white"}
            css={{
              opacity: isUserAuthorized ? 1 : 0.5,
              pointerEvents: isUserAuthorized ? "auto" : "none",
            }}
          >
            Zapisz
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
