import { MdAdd } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { ItemSortField, itemsSortFields } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue, SortFilter } from "@megaron/serializers";

import { ImportCostsButton } from "./ImportCostsButton";
import { TradeItemsFilters } from "./TradeItemsFilters";
import { TradeItemsMobileList } from "./TradeItemsMobileList";
import { TradeItemsTable } from "./TradeItemsTable";

const filtersSerializer = Serializers.object({
  searchText: Serializers.string,
  sort: Serializers.sortFilter(itemsSortFields).asJsonString().optional(),
  isLoyalty: Serializers.jsonString(Serializers.boolean).optional(),
  groups: Serializers.jsonString(Serializers.array(Serializers.string)),
  isArchived: Serializers.jsonString(Serializers.boolean).optional(),
  isInAvailability: Serializers.jsonString(Serializers.boolean).optional(),
});

export type ItemsFilters = SerializerValue<typeof filtersSerializer>;

export type SortField = SortFilter<ItemSortField>;

export const TradeItemsPage = () => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    searchText: "",
    sort: undefined,
    isLoyalty: undefined,
    groups: [],
    isArchived: undefined,
    isInAvailability: undefined,
  });

  const itemsQuery = useClientManager("docs").searchItems().useQuery({
    searchText: filters.searchText,
    sort: filters.sort,
    groups: filters.groups,
    isArchived: filters.isArchived,
    isLoyalty: filters.isLoyalty,
    isInAvailability: filters.isInAvailability,
  });

  return (
    <Page
      css={{
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <PageHeader
        actions={
          <div css={{ display: "flex", gap: "0.5rem" }}>
            <ImportCostsButton
              css={{ display: isMobile ? "none" : "block" }}
              onImportSuccess={async () => {
                await queryClient.cancelQueries(itemsQuery.key);
                await queryClient.invalidateQueries(itemsQuery.key);
              }}
            />
            <Button icon={<MdAdd />} onClick={() => navigate("/crm/items/new")}>
              Dodaj
            </Button>
          </div>
        }
      >
        Produkty
      </PageHeader>

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        hidePagination
      />

      <TradeItemsFilters
        filters={filters}
        handlers={{
          onGroupsChange: setFilter("groups"),
          onIsArchivedChange: setFilter("isArchived"),
          onIsLoyaltyChange: setFilter("isLoyalty"),
          onSortChange: setFilter("sort"),
          onIsInAvailabilityChange: setFilter("isInAvailability"),
        }}
      />

      <ImportCostsButton
        css={{ display: isMobile ? "block" : "none", marginRight: "auto", padding: "0 0 1rem" }}
        onImportSuccess={async () => {
          await queryClient.cancelQueries(itemsQuery.key);
          await queryClient.invalidateQueries(itemsQuery.key);
        }}
      />

      <QuerySkeleton query={itemsQuery}>
        {(items) =>
          isMobile ? (
            <TradeItemsMobileList items={items.items} />
          ) : (
            <TradeItemsTable
              items={items.items}
              isLoading={itemsQuery.isFetching}
              activeSortValue={filters.sort}
              onSortChange={setFilter("sort")}
            />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
