import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdWarningAmber } from "react-icons/md";

import { ProductAvailabiliyStatus } from "@megaron/crm-contracts";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { Tile, TileStripe } from "@megaron/dash-tile";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { statusLabelMap } from "../productAvailability/ProductAvailabilityTable";
import { AddProductAvailability } from "./AddProductAvailability";

type ProductData = {
  itemId: string;
  status: ProductAvailabiliyStatus;
};

type Props = {
  customerUuid: Uuid;
};

export const CustomerProductAvailability: React.FC<Props> = ({ customerUuid }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const [chosenProduct, setChosenProduct] = useState<ProductData | undefined>();

  const productAvailabilityQuery = useClientManager("crm")
    .customerProductsAvailabilityQuery()
    .useQuery({ customerId: customerUuid });

  const addProductAvailabilityDialog = useDialogRoute(`/add-product-availability`, ({ onClose }) => {
    const handleClose = () => {
      setChosenProduct(undefined);
      onClose();
    };

    return (
      <Dialog
        onClose={handleClose}
        header={"Dodaj dostępność produktu"}
        placement={isMobile ? "top" : "center"}
        css={{ width: isMobile ? "100%" : "500px" }}
      >
        <AddProductAvailability
          queryKey={productAvailabilityQuery.key}
          onClose={handleClose}
          customerUuid={customerUuid}
          data={chosenProduct}
        />
      </Dialog>
    );
  });

  const statusColorMap: Record<ProductAvailabiliyStatus, string> = {
    unavailable: theme.colors.danger,
    available: theme.colors.success,
  };

  return (
    <QuerySkeleton query={productAvailabilityQuery}>
      {(products) => (
        <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
          <SectionHeader isHr actions={<Button onClick={() => addProductAvailabilityDialog.open()}>Dodaj</Button>}>
            Dostępność produktów
          </SectionHeader>

          {products.length > 0 ? (
            <div css={{ display: "flex", gap: "8px", flexDirection: "column" }}>
              {products.map((product) => {
                return (
                  <Tile
                    key={product.itemId}
                    stripe={<TileStripe color={statusColorMap[product.status]} />}
                    onClick={() => {
                      setChosenProduct({ itemId: product.itemId, status: product.status });
                      addProductAvailabilityDialog.open();
                    }}
                    css={{ cursor: "pointer" }}
                  >
                    <div css={{ display: "flex", gap: "12px", justifyContent: "space-between" }}>
                      <span css={{ color: theme.colors.primary, fontWeight: 700, fontSize: "1rem" }}>
                        {product.itemName}
                      </span>
                      <span css={{ color: theme.colors.secondaryText, fontWeight: 400, fontSize: "1rem" }}>
                        {statusLabelMap[product.status] || product.status}
                      </span>
                      <span
                        css={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.4rem",
                          color: theme.colors.secondaryText,
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {new Date(product.date)
                          .toLocaleString("pl-PL", {
                            timeZone: "Europe/Warsaw",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                          .replace(",", "")}

                        {new Date(product.date) < new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) && (
                          <MdWarningAmber color={theme.colors.warning} />
                        )}
                      </span>
                    </div>
                  </Tile>
                );
              })}
            </div>
          ) : (
            <span
              css={{
                color: theme.colors.secondaryText,
                fontSize: "1rem",
                marginTop: "-0.5rem",
              }}
            >
              Brak danych dostępności produktów
            </span>
          )}

          {addProductAvailabilityDialog.element}
        </div>
      )}
    </QuerySkeleton>
  );
};
