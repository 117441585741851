import { useTheme } from "@emotion/react";

import { ProductAvailabiliyListObject, ProductAvailabiliyStatus } from "@megaron/crm-contracts";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { ItemSortField } from "@megaron/docs-contracts";

import { statusOptions } from "./AddProductAvailability";

type Props = {
  items: ProductAvailabiliyListObject[];
  isLoading: boolean;
};

export const statusLabelMap = statusOptions.reduce<Record<string, string>>((acc, { value, label }) => {
  acc[value] = label;
  return acc;
}, {});

export const ProductAvailabilityTable: React.FC<Props> = ({ items, isLoading }) => {
  const theme = useTheme();

  const statusColorMap: Record<ProductAvailabiliyStatus, string> = {
    unavailable: theme.colors.danger,
    available: theme.colors.success,
  };

  const tableColumns: TableHeadColumn<ItemSortField>[] = [
    ...tableHeadingsList.map(({ heading, sortField }) =>
      sortField
        ? ({
            isSortable: true,
            sortFieldName: sortField,
            label: heading,
          } as const)
        : ({
            isSortable: false,
            label: heading,
          } as const),
    ),
  ];

  const getTableRowCellsContent = (item: ProductAvailabiliyListObject): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: statusColorMap[item.status],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "20px",
            }}
          />
        ),
      },
      {
        isLink: true,
        element: <span css={{ color: theme.colors.primary, textWrap: "nowrap" }}>{item.itemName}</span>,
      },
      {
        isLink: false,
        cellCss: {
          color: theme.colors.primary,
          opacity: 1,
        },
        element: item.posName,
      },
      {
        isLink: false,
        cellCss: { color: theme.colors.secondaryText, opacity: 1 },
        element: statusLabelMap[item.status] || item.status,
      },
      {
        isLink: false,
        cellCss: { color: theme.colors.secondaryText, opacity: 1 },
        element: new Date(item.date)
          .toLocaleString("pl-PL", {
            timeZone: "Europe/Warsaw",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
          .replace(",", ""),
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table columns={tableColumns} css={{ padding: "0 2rem" }}>
        <TableBody
          isLoading={isLoading}
          rows={items.map((item) => ({
            cellsContent: getTableRowCellsContent(item),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

export const tableHeadingsList: { heading: string; sortField?: ItemSortField }[] = [
  { heading: "" },
  { heading: "Produkt" },
  { heading: "Punkt Sprzedaży" },
  { heading: "Status" },
  { heading: "Data" },
];
