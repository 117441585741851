import { ProductAvailabiliyStatus } from "@megaron/crm-contracts";
import { FilterList, SelectFilter, SelectFilterDocs } from "@megaron/dash-filters";
import { useDeviceType } from "@megaron/dash-mq";
import { Pagination } from "@megaron/dash-search";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { statusOptions } from "./AddProductAvailability";
import { ProductAvailabilityFilters } from "./ProductAvailabilityHome";

type Props = {
  filters: ProductAvailabilityFilters;
  handlers: {
    onPosChange: (pos: Uuid[]) => void;
    onItemIdsChange: (itemIds: string[]) => void;
    onStatusChange: (status: ProductAvailabiliyStatus[]) => void;
    onPageChange: (page: number) => void;
  };
  page: number;
  itemCount: number;
  pageSize: number;
};

export const ProductAvailabilityFiltersSection: React.FC<Props> = ({
  filters,
  handlers,
  page,
  itemCount,
  pageSize,
}) => {
  const { isMobile } = useDeviceType();

  const productsQuery = useClientManager("docs").searchItems().useQuery({});

  const productsOptions = productsQuery.data
    ? Array.from(new Set(productsQuery.data.items.map((product) => product.itemId))).map((itemId) => ({
        label: itemId,
        value: itemId,
      }))
    : [];

  const customersQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery({ categories: ["pos"] });

  const posOptions = customersQuery.data
    ? customersQuery.data.items.map((pos) => ({
        label: pos.name ?? pos.uuid,
        value: pos.uuid,
      }))
    : [];

  return (
    <div css={{ display: "flex", justifyContent: "space-between" }}>
      <FilterList>
        <SelectFilter
          label="Produkty"
          initiallySelectedValues={filters.itemIds ?? []}
          options={productsOptions ?? []}
          onSelectedChange={(options) =>
            handlers.onItemIdsChange(options.length > 0 ? options.map((option) => option.value) : [])
          }
          variant="multi-select"
        />

        <SelectFilter
          label="Punkty sprzedaży"
          initiallySelectedValues={filters.pos ?? []}
          options={posOptions ?? []}
          onSelectedChange={(options) =>
            handlers.onPosChange(options.length > 0 ? options.map((option) => option.value as Uuid) : [])
          }
          variant="multi-select"
        />

        <SelectFilter
          label={"Statusy"}
          variant="multi-select"
          onSelectedChange={(options) =>
            handlers.onStatusChange(options.map((option) => option.value as ProductAvailabiliyStatus))
          }
          initiallySelectedValues={filters.status ?? []}
          options={statusOptions.map((status) => status) ?? []}
        />
      </FilterList>
      <Pagination
        page={page}
        onPageChange={handlers.onPageChange}
        pageSize={pageSize}
        itemCount={itemCount}
        css={{ display: isMobile ? "none" : "flex" }}
      />
    </div>
  );
};
