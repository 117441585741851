import { MdOutlineShoppingCart } from "react-icons/md";

import { CustomerDetails } from "@megaron/crm-contracts";
import { useDeviceType } from "@megaron/dash-mq";

import { CustomerLatestOrders } from "./CustomerLatestOrders";
import { CustomerPotentialsList } from "./CustomerPotentialsList";
import { CustomerProductAvailability } from "./CustomerProductAvailability";
import { CustomerReports } from "./CustomerReports";
import { LoyaltyDetails } from "./LoyaltyDetails";
import { PayerPricingRules } from "./PayerPricingRules";
import { TabHeader } from "./TabHeader";

type Props = {
  customer: CustomerDetails;
};

export const SaleSection: React.FC<Props> = ({ customer }) => {
  const { isMobile } = useDeviceType();

  const isLoyalty = customer.categories.includes("loyaltyUser");
  const isPayer = customer.categories.includes("payer");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {!isMobile && <TabHeader icon={<MdOutlineShoppingCart size={18} />} text="Sprzedaż" />}

      {isPayer && <PayerPricingRules customerUuid={customer.uuid} postSaleDiscount={customer.postSaleDiscount} />}

      {!isLoyalty && <CustomerReports customerUuid={customer.uuid} date={new Date()} />}

      {!isLoyalty && <CustomerProductAvailability customerUuid={customer.uuid} />}

      {!isLoyalty && <CustomerLatestOrders customerUuid={customer.uuid} />}

      {isLoyalty && <LoyaltyDetails loyaltyUuid={customer.uuid} isCustomerArchived={customer.isArchived} />}

      <CustomerPotentialsList customerId={customer.uuid} />
    </div>
  );
};
