import { Theme, ThemeProvider } from "@emotion/react";
import { Outlet, Route } from "react-router-dom-v5-compat";

import { createTheme } from "@megaron/dash-default-theme";
import { useConfig } from "@megaron/dev";
import { LinkDoc } from "@megaron/docs-contracts";
import { ListingsPage } from "@megaron/loyalty-listings-admin-webapp";
import { OrderPage, OrdersHome } from "@megaron/loyalty-orders-admin-webapp";
import {
  BatchesHome,
  CodeGroupsHome,
  InspectProductCodeView,
  LabelBatchView,
  ProductionBatchView,
  UngroupCodeView,
} from "@megaron/loyalty-product-code-admin-webapp";
import { LinePage, QrSyncHome } from "@megaron/loyalty/qr-sync-webapp";
import { appLinkTreeToNavEntries, MegaraxWebappContainer } from "@megaron/megarax-webapps";
import { filterLinkTree } from "@megaron/megarax-webapps-home";

import backgroundImage from "./background_image.png";
import { ReactComponent as LogoComponent } from "./LoyaltyAdminLogo.svg";

const loyaltyTheme: Theme = createTheme("328");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useLoyaltyRouter: React.FC<Props> = ({ linkDocs }) => {
  return (
    <Route
      path="/specjalista"
      element={
        <ThemeProvider theme={loyaltyTheme}>
          <LoyaltyMegaraxWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="orders">
        <Route path="id/:orderId/*" element={<OrderPage />} />
        <Route path="" element={<OrdersHome />} />
      </Route>
      <Route path="product-codes/groups/*" element={<CodeGroupsHome />} />
      <Route path="product-codes/inspect-code" element={<InspectProductCodeView />} />
      <Route path="product-codes/inspect-code/:codeId" element={<InspectProductCodeView />} />
      <Route path="">
        <Route path="batches/*" element={<BatchesHome />} />
        <Route path="batches/ungroup-code" element={<UngroupCodeView />} />
        <Route path="batches/label-batch/:groupName" element={<LabelBatchView />} />
        <Route path="batches/production-batch/:groupName" element={<ProductionBatchView />} />
      </Route>
      <Route path="listings/*" element={<ListingsPage />} />
      <Route path="qr-sync">
        <Route path="" element={<QrSyncHome />} />
        <Route path="line/:lineId" element={<LinePage />} />
      </Route>
    </Route>
  );
};

const LoyaltyMegaraxWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const appLinkTree = fullLinkTree.find((appTree) => appTree.app === "loyaltyAdmin");

  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      backgroundImageSrc={backgroundImage}
      logo={<LogoComponent width="1em" height="1em" />}
      title="Specjalista"
      subtitle="megarax.net"
      bypassAuth={config.testUser.enabled}
      navEntries={appLinkTreeToNavEntries(appLinkTree)}
    >
      <Outlet />
    </MegaraxWebappContainer>
  );
};
