import Decimal from "decimal.js";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom-v5-compat";

import { Item } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";

import { TradeItemDetails } from "./TradeItemDetails";
import { TradeItemSizes } from "./TradeItemSizes";

export const TradeItemPage = () => {
  const { isMobile } = useDeviceType();

  const params = useParams<{ itemId: string }>();

  const itemQuery = useClientManager("crm")
    .itemQuery()
    .useQuery({ itemId: params.itemId || "" }, { enabled: !!params.itemId });

  return (
    <>
      <QuerySkeleton query={itemQuery} height="58px" width="100%">
        {(item) => <HeaderBar header={item.itemName || item.itemId} />}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: "72px",
        }}
      >
        <QuerySkeleton query={itemQuery} height="160px">
          {(item) => <TradeItemPageContent item={item} queryKey={itemQuery.key} />}
        </QuerySkeleton>
      </Page>
    </>
  );
};

const TradeItemPageContent = ({ item, queryKey }: { item: Item; queryKey: string | string[] }) => {
  const [index, setIndex] = useState(item.productId);
  const [group, setGroup] = useState(item.productGroup);
  const [size, setSize] = useState(item.size);
  const [pallet, setPallet] = useState(item.pallet);
  const [layer, setLayer] = useState(item.layer);
  const [box, setBox] = useState(item.box);
  const [isArchived, setIsArchived] = useState(item.isArchived);
  const [isLoyalty, setIsLoyalty] = useState(item.isLoyalty);
  const [isInAvailability, setIsInAvailability] = useState(item.isInAvailability);

  const [cost, setCost] = useState<string>(item.cost?.toFixed(2) || "");
  const [defaultProfit, setDefaultProfit] = useState<string>(item.defaultProfit?.toFixed(2) || "");

  const queryClient = useQueryClient();

  const toast = useToast();

  const itemMutation = useClientManager("crm").saveItem().useMutation();

  const handleSave = useCallback(() => {
    if (!index) {
      toast.error("Index jest wymagany");
      return;
    }

    if (!group) {
      toast.error("Grupa jest wymagana");
      return;
    }

    if (!size) {
      toast.error("Wielkość jest wymagana");
      return;
    }

    itemMutation.mutate(
      {
        ...item,
        productId: index,
        productGroup: group,
        size,
        pallet,
        layer,
        box,
        isArchived,
        isLoyalty,
        cost: cost ? new Decimal(cost) : null,
        defaultProfit: defaultProfit ? new Decimal(defaultProfit) : null,
        isInAvailability,
      },
      {
        onSuccess: () => {
          toast.info("Dane zostały zaktualizowane");
          queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
          toast.error("Wystąpił błąd podczas zapisywania danych");
        },
      },
    );
  }, [
    box,
    group,
    index,
    item,
    itemMutation,
    layer,
    pallet,
    size,
    toast,
    queryClient,
    queryKey,
    isArchived,
    cost,
    isLoyalty,
    defaultProfit,
  ]);

  return (
    <>
      <TradeItemDetails
        item={item}
        values={{ index, group, isArchived, cost, isLoyalty, defaultProfit, isInAvailability }}
        handlers={{
          onGroupChange: setGroup,
          onIndexChange: setIndex,
          onArchivedChange: setIsArchived,
          onCostChange: setCost,
          onIsLoyaltyChange: setIsLoyalty,
          onDefaultProfitChange: setDefaultProfit,
          onIsInAvailabilityChange: setIsInAvailability,
        }}
      />
      <TradeItemSizes
        values={{ size: size.toFixed(0), pallet: pallet ?? 0, layer: layer || 0, box: box || 0 }}
        handlers={{
          onBoxChange: setBox,
          onLayerChange: setLayer,
          onPalletChange: setPallet,
          onSizeChange: (v) => setSize(new Decimal(v || 0)),
        }}
      />
      <Button onClick={handleSave} css={{ alignSelf: "flex-end", marginTop: "1rem" }}>
        Zapisz
      </Button>
    </>
  );
};
