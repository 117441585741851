import { Theme, ThemeProvider } from "@emotion/react";
import { NewRouterBreadcrumbsBar } from "@megarax/ui-components";
import { MdGroups } from "react-icons/md";
import { Navigate, Outlet, Route } from "react-router-dom-v5-compat";

import {
  AddCustomerPage,
  CustomerPage,
  CustomerPageRedirect,
  CustomersViewPage,
  MyAffiliateCodePage,
  MyAffiliateCodesPage,
  NewTradeItemPage,
  OrderDraftPage,
  OrderDraftsPage,
  OrderPage,
  OrdersPage,
  PostSaleDiscountsPage,
  ProductAvailabilityHome,
  ProfitTargetsPage,
  RegionProfitMetricsPage,
  RegionRealizationPage,
  RegionsHome,
  TradeItemPage,
  TradeItemsPage,
  TrainingHome,
  UserHomepage,
} from "@megaron/crm-webapp";
import { createTheme } from "@megaron/dash-default-theme";
import { useConfig } from "@megaron/dev";
import { LinkDoc } from "@megaron/docs-contracts";
import { getPosCustomersUrl } from "@megaron/megarax-links";
import { ChainsHomeContainer } from "@megaron/megarax-v2-chains";
import { TradeRouteDetailsContainer, TradeRoutesHomeContainer } from "@megaron/megarax-v2-customer-map";
import { MacroregionsHomeContainer } from "@megaron/megarax-v2-macroregions";
import { DraftDetailsContainer, MyOrderHomeContainer, NewDraftOrderContainer } from "@megaron/megarax-v2-my-orders";
import { PriceReportFormContainer } from "@megaron/megarax-v2-my-price-reports";
import { PriceListDetailsContainer, PriceListHomeContainer } from "@megaron/megarax-v2-price-lists";
import { SubjectsViewContainer } from "@megaron/megarax-v2-price-reports";
import { RegionsHomeContainer } from "@megaron/megarax-v2-regions";
import { HomeView, MegaraxProviders } from "@megaron/megarax-v2-root";
import { SalespeopleHomeContainer, SalespersonDetailsContainer } from "@megaron/megarax-v2-sales-people";
import {
  DayViewContainer,
  MyVisitsRedirect,
  VisitRedirect,
  VisitsRedirect,
  WeekViewContainer,
} from "@megaron/megarax-v2-visits";
import { appLinkTreeToNavEntries, MegaraxWebappContainer } from "@megaron/megarax-webapps";
import { filterLinkTree } from "@megaron/megarax-webapps-home";

const crmTheme: Theme = createTheme("273");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useCrmRouter: React.FC<Props> = ({ linkDocs }) => {
  return (
    <Route
      path="/crm"
      element={
        <ThemeProvider theme={crmTheme}>
          <CrmMegaraxWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="customers/*">
        <Route path="*" element={<Navigate to="/crm/customers/new-list" replace />} />
        <Route path=":viewId/*" element={<CustomersViewPage />} />
        <Route path="new/*" element={<AddCustomerPage />} />
        <Route path="id/:customerId/*" element={<CustomerPage />} />
      </Route>
      <Route path="regions/*">
        <Route path="*" element={<RegionsHome />} />
      </Route>
      <Route path="product-availability/*">
        <Route path="*" element={<ProductAvailabilityHome />} />
      </Route>
      <Route path="training/*">
        <Route path="*" element={<TrainingHome />} />
      </Route>
      <Route path="items">
        <Route path="" element={<TradeItemsPage />} />
        <Route path="new" element={<NewTradeItemPage />} />
        <Route path=":itemId/*" element={<TradeItemPage />} />
      </Route>
      <Route path="post-sale-discounts" element={<PostSaleDiscountsPage />} />
      <Route path="profit-targets" element={<ProfitTargetsPage />} />
      <Route path="region-profit-metrics">
        <Route path="" element={<RegionProfitMetricsPage />} />
        <Route path=":region" element={<RegionRealizationPage />} />
      </Route>
      <Route path="user-home" element={<UserHomepage />} />
      <Route path="orders">
        <Route path="" element={<OrdersPage />} />
        <Route path=":orderId" element={<OrderPage />} />
      </Route>
      <Route path="order-drafts">
        <Route path="" element={<OrderDraftsPage />} />
        <Route path="new/*" element={<OrderDraftPage isNew />} />
        <Route path=":orderId/*" element={<OrderDraftPage />} />
      </Route>
      <Route path="my-affiliate-codes">
        <Route path="" element={<MyAffiliateCodesPage />} />
        <Route path=":affiliateCodeId/*" element={<MyAffiliateCodePage />} />
      </Route>

      {/* Crm (POS) */}
      <Route path="pos" element={<PosCrmMegaraxWebappContainer />}>
        <Route path="" element={<HomeView />} />
        <Route path="cenniki">
          <Route path="" element={<PriceListHomeContainer />}></Route>
          <Route path="nowy" element={<PriceListHomeContainer />}></Route>
          <Route path=":uuid/:tab/*" element={<PriceListDetailsContainer />}></Route>
        </Route>

        <Route path="moje_zamowienia">
          <Route path="nowe" element={<NewDraftOrderContainer />}></Route>
          <Route path=":uuid/*" element={<DraftDetailsContainer />}></Route>
          <Route path="" element={<MyOrderHomeContainer />}></Route>
        </Route>
        <Route path="moje_ankiety_cenowe" element={<PriceReportFormContainer />} />
        <Route path="klienci">
          <Route path="" element={<Navigate to={`/crm${getPosCustomersUrl()}`} />} />
          <Route path=":uuid/*" element={<CustomerPageRedirect />} />
        </Route>
        <Route path="regiony" element={<RegionsHomeContainer />} />
        <Route path="makroregiony" element={<MacroregionsHomeContainer />} />
        <Route path="sieci" element={<ChainsHomeContainer />} />
        <Route path="moje_wizyty" element={<MyVisitsRedirect />} />
        <Route path="handlowcy">
          <Route path={`:userId`}>
            <Route path="" element={<SalespersonDetailsContainer />} />
            <Route path="wizyty">
              <Route path="" element={<VisitsRedirect />} />
              <Route path="tydzien">
                <Route path="" element={<VisitsRedirect />} />
                <Route path=":date/wizyta/:uuid" element={<VisitRedirect />} />
                <Route path=":date/*" element={<WeekViewContainer />} />
              </Route>
              <Route path="dzien">
                <Route path="" element={<VisitsRedirect />} />
                <Route path=":date/wizyta/:uuid" element={<VisitRedirect />} />
                <Route path=":date/*" element={<DayViewContainer />} />
              </Route>
            </Route>
          </Route>
          <Route path="" element={<SalespeopleHomeContainer />} />
          <Route path="nowy" element={<SalespeopleHomeContainer />} />
          <Route path="przejechane_kilometry" element={<SalespeopleHomeContainer />} />
        </Route>
        <Route path="trasy">
          <Route path="" element={<TradeRoutesHomeContainer />} />
          <Route path=":tradeRouteUuid" element={<TradeRouteDetailsContainer />}></Route>
        </Route>
        <Route path="ankiety_cenowe">
          <Route path="" element={<Navigate to="/crm/pos/ankiety_cenowe/produkty" />} />
          <Route path="produkty" element={<SubjectsViewContainer />} />
        </Route>
      </Route>
    </Route>
  );
};

const CrmMegaraxWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const crmLinkTree = fullLinkTree.find((appTree) => appTree.app === "crm");

  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      logo={<MdGroups width="1em" height="1em" />}
      title="CRM"
      app="crm"
      subtitle="megarax.net"
      bypassAuth={config.testUser.enabled}
      navEntries={appLinkTreeToNavEntries(crmLinkTree)}
    >
      <MegaraxProviders>
        <Outlet />
      </MegaraxProviders>
    </MegaraxWebappContainer>
  );
};

const PosCrmMegaraxWebappContainer = () => {
  return (
    <MegaraxProviders>
      <NewRouterBreadcrumbsBar />
      <Outlet />
    </MegaraxProviders>
  );
};
