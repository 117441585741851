import { useTheme } from "@emotion/react";
import React from "react";
import { BsPersonCheckFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { PiSignature } from "react-icons/pi";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { ThreadDto } from "@megaron/invoices-contracts";

import { NoSignsTile } from "./NoSignsTiles";
import { SignatureDialog } from "./SignatureDialog";
import { SignatureTile } from "./SignatureTile";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
  isPrint?: boolean;
};

const SignatureSectionItem: React.FC<{
  title: string;
  icon: React.ReactNode;
  description: string;
  signatures: any[];
  isSigned: boolean;
  isPrint?: boolean;
  dialogOpen: () => void;
  buttonText: string;
  buttonDisabled: boolean;
  queryKey: string | string[];
  thread: ThreadDto;
}> = ({
  title,
  icon,
  description,
  signatures,
  isSigned,
  isPrint,
  dialogOpen,
  buttonText,
  buttonDisabled,
  queryKey,
  thread,
}) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {!isPrint && (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
            whiteSpace: "nowrap",
          }}
        >
          <div
            css={{
              display: "flex",
              gap: "12px",
              fontSize: "18px",
              fontWeight: 700,
              color: isSigned ? theme.colors.success : theme.colors.primary,
              alignItems: "center",
            }}
          >
            <button
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                border: "none",
                padding: "0.25rem",
                width: "1.25rem",
                height: "1.25rem",
                background: isSigned ? theme.colors.success : theme.colors.primaryLight,
                flexShrink: 0,
                color: isSigned ? "white" : theme.colors.primary,
              }}
            >
              {icon}
            </button>
            <span>{title}</span>
          </div>

          <hr
            css={{
              width: "100%",
              border: "none",
              borderTop: `1px solid ${theme.colors.border}`,
              margin: 0,
            }}
          />
        </div>
      )}
      <span css={{ fontSize: "14px", color: theme.colors.secondaryText }}>{description}</span>
      <div css={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {signatures.length > 0 ? (
          signatures.map((signature) => (
            <SignatureTile
              key={signature.uuid}
              signature={signature}
              queryKey={queryKey}
              thread={thread}
              isPrint={isPrint}
              isValid={isSigned}
              type={title === "Podpis jakości" ? "validity" : "budget"}
            />
          ))
        ) : (
          <NoSignsTile />
        )}
      </div>
      {!isPrint && (
        <Button
          variant={signatures.length > 0 ? "outline" : "primary"}
          css={{ width: "100%" }}
          onClick={dialogOpen}
          icon={<PiSignature />}
          isDisabled={buttonDisabled}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export const SignatureSection: React.FC<Props> = ({ thread, queryKey, isPrint }) => {
  const theme = useTheme();

  const dialogRoute = (type: "validity" | "budget") => (type === "budget" ? "/podpis-budzetu" : "/podpis-waznosci");

  const validityDialog = useDialogRoute(dialogRoute("validity"), ({ onClose }) => (
    <SignatureDialog thread={thread} queryKey={queryKey} type="validity" onClose={onClose} />
  ));

  const budgetDialog = useDialogRoute(dialogRoute("budget"), ({ onClose }) => (
    <SignatureDialog thread={thread} queryKey={queryKey} type="budget" onClose={onClose} />
  ));

  const isValiditySigned = thread.signedValidityRelative.toNumber() === 1;
  const isBudgetSigned = thread.signedBudgetRelative.toNumber() === 1;
  const validitySignatures = thread.validitySignatures;
  const budgetSignatures = thread.budgetSignatures;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "32px" }}>
      <SignatureSectionItem
        title="Podpis jakości"
        icon={<BsPersonCheckFill />}
        description="Sprawdził(a) prawdziwość, jakość i ilość dostawy:"
        signatures={validitySignatures}
        isSigned={isValiditySigned}
        isPrint={isPrint}
        dialogOpen={validityDialog.open}
        buttonText="Podpisz lub zasugeruj"
        buttonDisabled={thread.status !== "open"}
        queryKey={queryKey}
        thread={thread}
      />

      <SignatureSectionItem
        title="Podpis budżetu"
        icon={<MdAttachMoney />}
        description="Podpis dysponenta budżetu/prowadzącego inwestycję:"
        signatures={budgetSignatures}
        isSigned={isBudgetSigned}
        isPrint={isPrint}
        dialogOpen={budgetDialog.open}
        buttonText={"Podpisz lub zasugeruj budżet"}
        buttonDisabled={thread.status !== "open"}
        queryKey={queryKey}
        thread={thread}
      />

      {validityDialog.element}
      {budgetDialog.element}
    </div>
  );
};
