import { useState } from "react";
import { useQueryClient } from "react-query";

import { ProductAvailabiliyStatus } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { Select, SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  data?: {
    itemId: string;
    status: ProductAvailabiliyStatus;
  };
  customerUuid: Uuid;
  queryKey: string | string[];
  onClose: () => void;
};

export const statusOptions: { label: string; value: ProductAvailabiliyStatus }[] = [
  { label: "Brak", value: "unavailable" },
  { label: "Dostępny", value: "available" },
];

export const AddProductAvailability: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const [status, setStatus] = useState<ProductAvailabiliyStatus>(props.data?.status ?? "unavailable");
  const [itemId, setItemId] = useState<string>(props.data?.itemId ?? "");
  const [error, setError] = useState<string>("");

  const addProductAvailabilityMutation = useClientManager("crm").addProductAvailability().useMutation();

  const docs = useServiceClient("docs");

  const addProductAvailability = () => {
    if (!itemId || !status) {
      setError("Wszystkie pola muszą być wypełnione");
      return;
    }

    setError("");

    const data = {
      itemId,
      posId: props.customerUuid,
      status,
      date: new Date(),
    };

    addProductAvailabilityMutation.mutate(data, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(props.queryKey);
        props.onClose();
      },
      onError: () => {
        toast.error(`Błąd podczas zapisywania dostępności produktu`);
      },
    });
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        overflowY: "auto",
        maxHeight: "calc(100vh - 150px)",
        padding: "2px",
      }}
    >
      <SelectDialog
        variant="single-select"
        label="Produkt"
        initiallySelectedValues={itemId ? [itemId] : []}
        onSelect={(option) => {
          setItemId(option?.value ?? "");
        }}
        search={async (search) => {
          const results = await docs.searchItems({
            searchText: search,
          });

          if (!results || !results.value?.items) {
            return [];
          }

          const filteredItems = results.value.items.filter((i) => i.isInAvailability === true);

          return filteredItems.map((item) => ({
            label: item.itemName ?? item.itemId,
            value: item.itemId,
          }));
        }}
      />

      <Select
        label="Status"
        options={statusOptions}
        value={status}
        onChange={(selectedValue: string) => setStatus(selectedValue as ProductAvailabiliyStatus)}
        css={{ flex: 1 }}
      />

      {error && (
        <div
          css={{
            color: "red",
            fontSize: "14px",
            marginBottom: "8px",
          }}
        >
          {error}
        </div>
      )}

      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "18px",
          marginTop: "auto",
        }}
      >
        <Button
          onClick={addProductAvailability}
          isLoading={addProductAvailabilityMutation.isLoading}
          spinnerColor={"white"}
        >
          Dodaj
        </Button>
      </div>
    </div>
  );
};
