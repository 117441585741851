import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { currencies } from "./currency";

export const myOrderSortFields = ["placedAt"] as const;
export type MyOrderSortField = (typeof myOrderSortFields)[number];

const orderDraftLineDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  item: Serializers.object({ gtin: Serializers.gtin, name: Serializers.string }),
  promoPrice: Serializers.decimal.nullable(),
  quantity: Serializers.integer,
  lineNetTotal: Serializers.decimal,
  finalPrice: Serializers.decimal,
  basePrice: Serializers.decimal,
});

export const orderDraftDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  payerDepartmentUuid: uuidSerializer,
  payerDepartmentName: Serializers.string,
  billToUuid: uuidSerializer,
  billToName: Serializers.string,
  sellToUuid: uuidSerializer,
  sellToName: Serializers.string,
  isFinalized: Serializers.boolean,
  finalizedAt: Serializers.datetime.nullable(),
  priceListUuid: uuidSerializer,
  lines: Serializers.array(orderDraftLineDtoSerializer),
  ownerId: Serializers.string,
  isDiscarded: Serializers.boolean,
  startedAt: Serializers.datetime,
  discardedAt: Serializers.datetime.nullable(),
  netTotal: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
});

export const myDraftSimpleCustomerSerializer = Serializers.object({
  uuid: uuidSerializer,
  name: Serializers.string.optional(),
});

export const createCopyInputSerializer = Serializers.object({
  uuid: uuidSerializer,
  templateUuid: uuidSerializer,
});

export const myDraftSimpleSerializer = Serializers.object({
  uuid: uuidSerializer,
  buyer: myDraftSimpleCustomerSerializer,
  payer: myDraftSimpleCustomerSerializer,
  placedAt: Serializers.datetime.nullable(),
  draftStartedAt: Serializers.datetime,
  isPlaced: Serializers.boolean,
});

export const addLineInputSerializer = Serializers.object({
  lineUuid: uuidSerializer,
  item: Serializers.object({ gtin: Serializers.gtin }),
  quantity: Serializers.integer,
  promoPrice: Serializers.decimal.nullable(),
});

export const updateLineInputSerializer = Serializers.object({
  quantity: Serializers.integer,
  promoPrice: Serializers.decimal.nullable(),
});

export const startDraftInputSerializer = Serializers.object({
  uuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  buyer: uuidSerializer,
  payer: uuidSerializer,
  recipient: uuidSerializer,
  priceList: uuidSerializer,
});

export const deliveryAddressSerializer = Serializers.object({
  houseNumber: Serializers.string,
  country: Serializers.string,
  locality: Serializers.string,
  name: Serializers.string,
  postalCode: Serializers.string,
  street: Serializers.string,
});

export const placeOrderInputSerializer = Serializers.object({
  deliveryAddress: deliveryAddressSerializer,
  deliveryDeadline: Serializers.dateOnly,
  externalOrderNumber: Serializers.string,
  comment: Serializers.string,
});

export const editCustomersInputSerializer = Serializers.object({
  sellToUuid: uuidSerializer,
  billToUuid: uuidSerializer,
  payerDepartmentUuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  priceListUuid: uuidSerializer,
});

export const orderDraftActions = {
  myDraftListQuery: HttpAction({
    path: "/sales/orderDrafts",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.paginatedList(
      Serializers.object({
        uuid: uuidSerializer,
        placedAt: Serializers.datetime.nullable(),
        draftStartedAt: Serializers.datetime,
        isPlaced: Serializers.boolean,

        sellToName: Serializers.string,
        sellToUuid: uuidSerializer,

        billToName: Serializers.string,
        billToUuid: uuidSerializer,
      }),
    ),
    errorSerializer: Serializers.stringOneOf("DraftNotFound", "PriceListNotFound", "DraftDiscarded", "DraftFinalized"),
  }),

  myDraftQuery: HttpAction({
    path: "/sales/orderDrafts/:uuid",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    valueSerializer: orderDraftDtoSerializer,
    errorSerializer: Serializers.stringOneOf("DraftNotFound", "PriceListNotFound", "DraftDiscarded", "DraftFinalized"),
  }),

  startDraft: HttpAction({
    path: "/sales/orderDrafts",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: startDraftInputSerializer,
    errorSerializer: Serializers.stringOneOf("InvalidDiscountRate"),
  }),

  placeOrder: HttpAction({
    path: "/sales/orderDrafts/:uuid/place",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    bodySerializer: placeOrderInputSerializer,
    errorSerializer: Serializers.stringOneOf(
      "PriceListNotFound",
      "OrderNotFound",
      "DraftNotOpen",
      "ItemNotFound",
      "UserNotOrderOwner",
      "UnknownGtin",
    ),
  }),
  discardDraft: HttpAction({
    path: "/sales/orderDrafts/:uuid",
    method: "delete",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
  }),
  editCustomers: HttpAction({
    path: "/sales/orderDrafts/:uuid/customers",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    bodySerializer: editCustomersInputSerializer,
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
  }),
  addLine: HttpAction({
    path: "/sales/orderDrafts/:uuid/lines",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    bodySerializer: addLineInputSerializer,
    errorSerializer: Serializers.stringOneOf(
      "OrderNotFound",
      "DraftNotOpen",
      "UserNotOrderOwner",
      "LineAlreadyExists",
      "InvalidLineQuantity",
    ),
  }),
  updateLine: HttpAction({
    path: "/sales/orderDrafts/:uuid/lines/:lineUuid",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
      lineUuid: uuidSerializer,
    }),
    bodySerializer: updateLineInputSerializer,
    errorSerializer: Serializers.stringOneOf(
      "OrderNotFound",
      "DraftNotOpen",
      "UserNotOrderOwner",
      "LineDoesNotExist",
      "InvalidLineQuantity",
    ),
  }),
  removeLine: HttpAction({
    path: "/sales/orderDrafts/:uuid/lines/:lineUuid",
    method: "delete",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
      lineUuid: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner", "LineDoesNotExist"),
  }),
  createCopy: HttpAction({
    path: "/sales/orderDrafts/createCopy",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: createCopyInputSerializer,
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
  }),
};
