import { useTheme } from "@emotion/react";
import { MdAdd } from "react-icons/md";

import { ProductAvailabiliyStatus, productAvailabiliyStatuses } from "@megaron/crm-contracts";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { Tile, TileStripe } from "@megaron/dash-tile";
import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { AddProductAvailability } from "./AddProductAvailability";
import { ProductAvailabilityFiltersSection } from "./ProductAvailabilityFilters";
import { ProductAvailabilityTable, statusLabelMap } from "./ProductAvailabilityTable";

const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  status: Serializers.jsonString(Serializers.stringOneOf(...productAvailabiliyStatuses).array()).optional(),
  itemIds: Serializers.jsonString(Serializers.string.array()).optional(),
  pos: Serializers.jsonString(uuidSerializer.array()).optional(),
});

export type ProductAvailabilityFilters = SerializerValue<typeof filtersSerializer>;

export const ProductAvailabilityHome = () => {
  const { isMobile } = useDeviceType();
  const theme = useTheme();
  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    page: 0,
    status: undefined,
    itemIds: undefined,
    pos: undefined,
  });

  const productAvailabilityQuery = useClientManager("crm").allProductsAvailabilityQuery().useQuery({
    offset: filters.page,
    status: filters.status,
    itemIds: filters.itemIds,
    pos: filters.pos,
  });

  const addProductAvailabilityDialog = useDialogRoute(`/add-product-availability`, ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header={"Dodaj dostępność produktu"}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "500px" }}
    >
      <AddProductAvailability queryKey={productAvailabilityQuery.key} onClose={onClose} />
    </Dialog>
  ));

  const statusColorMap: Record<ProductAvailabiliyStatus, string> = {
    unavailable: theme.colors.danger,
    available: theme.colors.success,
  };

  return (
    <>
      <Page
        css={{
          maxWidth: "1600px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <PageHeader
          actions={
            <div css={{ display: "flex", gap: "0.5rem" }}>
              <Button icon={<MdAdd />} onClick={addProductAvailabilityDialog.open}>
                Dodaj
              </Button>
            </div>
          }
        >
          Dostępność produktów
        </PageHeader>

        <ProductAvailabilityFiltersSection
          filters={filters}
          handlers={{
            onPageChange: setFilter("page"),
            onItemIdsChange: setFilter("itemIds"),
            onPosChange: setFilter("pos"),
            onStatusChange: setFilter("status"),
          }}
          page={filters.page}
          pageSize={50}
          itemCount={productAvailabilityQuery.data?.totalCount ?? 0}
        />
        {isMobile ? (
          <QuerySkeleton query={productAvailabilityQuery}>
            {(data) => (
              <div css={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                {data.items.map((product) => {
                  return (
                    <Tile stripe={<TileStripe color={statusColorMap[product.status]} />}>
                      <div css={{ display: "flex", gap: "12px", justifyContent: "space-between" }}>
                        <span css={{ color: theme.colors.primary, fontWeight: 700, fontSize: "1rem" }}>
                          {product.itemName}
                        </span>
                        <span
                          css={{
                            color: theme.colors.primary,
                            fontWeight: 700,
                            fontSize: "1rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "120px",
                          }}
                        >
                          {product.posName}
                        </span>

                        <span css={{ color: theme.colors.secondaryText, fontWeight: 400, fontSize: "1rem" }}>
                          {new Date(product.date)
                            .toLocaleString("pl-PL", {
                              timeZone: "Europe/Warsaw",
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                            .replace(",", "")}
                        </span>
                      </div>
                    </Tile>
                  );
                })}
              </div>
            )}
          </QuerySkeleton>
        ) : (
          <QuerySkeleton query={productAvailabilityQuery}>
            {(data) => <ProductAvailabilityTable items={data.items} isLoading={productAvailabilityQuery.isFetching} />}
          </QuerySkeleton>
        )}
      </Page>
      {addProductAvailabilityDialog.element}
    </>
  );
};
