import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { format } from "date-fns";
import Decimal from "decimal.js";
import { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdInfoOutline, MdOutlineSave } from "react-icons/md";
import { PiSignature } from "react-icons/pi";
import { useQueryClient } from "react-query";

import { Avatar } from "@megaron/dash-avatar";
import { Dialog } from "@megaron/dash-dialog";
import { Button, DecimalField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { useToast } from "@megaron/dash-toast";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { BudgetSignature, ThreadDto, ValiditySignature } from "@megaron/invoices-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";

import { BudgetTile } from "./BudgetTile";

type SignatureType = "budget" | "validity";

type Props = {
  signature: BudgetSignature | ValiditySignature;
  queryKey: string | string[];
  thread: ThreadDto;
  isValid: boolean;
  isPrint?: boolean;
  type: SignatureType;
};

export const SignatureTile: React.FC<Props> = ({ signature, queryKey, thread, isValid, isPrint, type }) => {
  const theme = useTheme();
  const toast = useToast();
  const queryClient = useQueryClient();
  const auth = useContext(IamAuthContext);
  const { profile } = useProfiles();
  const { isMobile } = useDeviceType();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>(signature.subject);
  const [signAmount, setSignAmount] = useState<string>((signature.amount as Decimal).toFixed(2).toString());
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const profileData = profile(signature.signedBy);
  const formattedDate = format(new Date(signature.signedAt), "dd.MM.yyyy");
  const fullName = profileData?.firstName ? `${profileData?.firstName} ${profileData?.lastName}` : "Imię Nazwisko";
  const remainingProcentage = (Number(signature.amount) / Number(thread.invoice.total)) * 100;

  const amount = `${formatCurrency((signature.amount as Decimal).toNumber(), thread.invoice.currency)}${
    !isNaN(remainingProcentage) ? ` (${remainingProcentage.toFixed(2)}%)` : ""
  }`;

  const isOpen = thread.status === "open";

  const removeBudgetSignatureMutation = useClientManager("invoices").removeBudgetSignature().useMutation();
  const removeValiditySignatureMutation = useClientManager("invoices").removeValiditySignature().useMutation();
  const signBudgetSignatureMutation = useClientManager("invoices").signBudget().useMutation();
  const signValiditySignatureMutation = useClientManager("invoices").signValidity().useMutation();

  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser?.email : undefined;
  const isUserSignature = signature.signedBy === userEmail;

  const budgetDocId = type === "budget" ? ResourceId("budget", (signature as BudgetSignature).budgetUuid) : null;
  const budgetQuery = budgetDocId
    ? useClientManager("docs")
        .retrieveDocs()
        .useQuery({ docIds: [budgetDocId] })
    : { data: [] };

  const budget = budgetQuery.data?.[0]?.docType === "budget" ? budgetQuery.data[0] : null;

  useEffect(() => {
    if (type === "budget" && signature.isSuggestion) {
      const userGroups = auth.iamUser?.userType === "megarax" ? auth.iamUser.groups.map((group) => group.id) : [];
      const isAuthorized = budget ? userGroups.some((group) => (budget.managerGroupIds ?? []).includes(group)) : false;
      setIsAuthorized(isAuthorized);
    }
  }, [budget]);

  const removeSignature = () => {
    if (type === "budget") {
      removeBudgetSignatureMutation.mutate(
        { threadUuid: thread.uuid, signatureUuid: signature.uuid },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            setIsDialogOpen(false);
          },
          onError: () => {
            toast.error("Error", "Coś poszło nie tak");
          },
        },
      );
    } else if (type === "validity") {
      removeValiditySignatureMutation.mutate(
        { threadUuid: thread.uuid, signatureUuid: signature.uuid },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            setIsDialogOpen(false);
          },
          onError: () => {
            toast.error("Error", "Coś poszło nie tak");
          },
        },
      );
    }
  };

  const signOrUpdateSignature = async (action: "save" | "sign") => {
    if (type === "budget" && budget && budget.docType === "budget") {
      await signBudgetSignatureMutation.mutateAsync(
        {
          uuid: signature.uuid,
          subject: subject,
          amount: new Decimal(signAmount),
          threadUuid: thread.uuid,
          budgetUuid: budget.uuid,
          isSuggestion: action === "sign" ? false : signature.isSuggestion,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            setIsDialogOpen(false);
          },
          onError: () => {
            toast.error("Error", "Coś poszło nie tak");
          },
        },
      );
    } else if (type === "validity") {
      await signValiditySignatureMutation.mutateAsync(
        {
          uuid: signature.uuid,
          subject: subject,
          amount: new Decimal(signAmount),
          threadUuid: thread.uuid,
          isSuggestion: action !== "sign",
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            setIsDialogOpen(false);
          },
          onError: () => {
            toast.error("Error", "Coś poszło nie tak");
          },
        },
      );
    }
  };

  const handleSign = () => signOrUpdateSignature("sign");
  const handleSave = () => signOrUpdateSignature("save");

  if (isPrint && signature.isSuggestion) return null;

  return (
    <>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          borderRadius: theme.smallBorderRadius,
          background: signature.isSuggestion ? "transparent" : "white",
          border: `1px ${signature.isSuggestion ? "dashed" : "solid"} ${theme.colors.border}`,
          padding: "12px 16px",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          cursor: isOpen ? "pointer" : "default",
          justifyContent: "space-between",
          ":hover": {
            border: `1px ${signature.isSuggestion ? "dashed" : "solid"} ${
              isOpen ? theme.colors.primary : theme.colors.border
            }`,
          },
        }}
        onClick={() => {
          if (isOpen) setIsDialogOpen(true);
        }}
      >
        <div css={{ display: "flex", alignItems: "center" }}>
          {signature.isSuggestion ? (
            <div
              css={{
                display: "flex",
                border: `1px dashed ${theme.colors.primary}`,
                borderRadius: "50px",
                color: theme.colors.primary,
                padding: "6px",
              }}
            >
              <PiSignature />
            </div>
          ) : (
            <>{!isPrint && <Avatar src={profileData?.profilePictureUrl} />} </>
          )}

          <div css={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
            {type === "budget" && signature.isSuggestion ? (
              <span css={{ fontSize: "16px" }}>Podpisz</span>
            ) : (
              <>
                <span css={{ fontSize: "16px" }}>{fullName}</span>
                <span css={{ fontSize: "14px", color: theme.colors.secondaryText }}>{formattedDate}</span>{" "}
              </>
            )}
          </div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            opacity: type === "budget" && signature.isSuggestion ? 0.5 : 1,
          }}
        >
          <span css={{ fontSize: "14px" }}>{signature.subject}</span>
          <span
            css={{
              fontSize: "16px",
              color:
                isValid && !(type === "budget" && signature.isSuggestion) ? theme.colors.success : theme.colors.primary,
              fontWeight: isValid ? 700 : 400,
            }}
          >
            {amount}
          </span>
          {type === "budget" && budget && (
            <span css={{ fontSize: "14px", fontWeight: 700 }}>
              {budget.name}
              <span css={{ color: theme.colors.secondaryText }}> {budget.budgetNumber}</span>
            </span>
          )}
        </div>
      </div>
      {isDialogOpen && (
        <Dialog
          onClose={() => setIsDialogOpen(false)}
          placement={isMobile ? "top" : "center"}
          header={"Edytuj podpis"}
          css={{ width: "400px" }}
        >
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {type === "budget" && budget && (
              <div>
                <label
                  css={{
                    color: theme.colors.primary,
                    fontSize: "0.875rem",
                    fontWeight: 700,
                    marginBottom: "0.5rem",
                  }}
                >
                  Budżet
                </label>
                <BudgetTile budget={budget} />
              </div>
            )}

            <TextField label="Temat" value={subject} onChange={(e) => setSubject(e)} />
            <DecimalField label="Kwota" value={signAmount} onChange={(e) => setSignAmount(e)} />
            {!isUserSignature && (
              <span
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: theme.colors.primary,
                  fontSize: "14px",
                  padding: "0 8px",
                }}
              >
                <MdInfoOutline size={16} />
                Dane podpisu zostaną zastąpione Twoimi danymi.
              </span>
            )}
            <div css={{ display: "flex", justifyContent: "right", gap: "8px" }}>
              <Button onClick={removeSignature} variant="outline" color="danger" icon={<MdDeleteOutline />}>
                Usuń
              </Button>

              <Button onClick={handleSave} icon={<MdOutlineSave />}>
                {!isUserSignature ? "Zastąp" : "Zapisz"}
              </Button>

              {signature.isSuggestion &&
                ((type === "budget" && isAuthorized) || type === "validity" ? (
                  <Button onClick={handleSign} variant="primary" icon={<PiSignature />}>
                    Podpisz
                  </Button>
                ) : null)}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
