import { AiOutlineBarcode, AiOutlineBgColors, AiOutlineExperiment, AiOutlineTag } from "react-icons/ai";
import { IconType } from "react-icons/lib";
import {
  MdAssignment,
  MdAttachMoney,
  MdBadge,
  MdCalendarMonth,
  MdChecklist,
  MdDescription,
  MdDirections,
  MdEditDocument,
  MdEventBusy,
  MdFactory,
  MdFolder,
  MdFolderCopy,
  MdFormatListBulleted,
  MdGroups,
  MdInbox,
  MdLink,
  MdListAlt,
  MdLocalShipping,
  MdMap,
  MdMoreHoriz,
  MdOutlineChecklist,
  MdOutlineComputer,
  MdOutlineDiscount,
  MdOutlineHome,
  MdOutlineMode,
  MdOutlineProductionQuantityLimits,
  MdOutlineTrendingUp,
  MdPallet,
  MdPerson,
  MdPersonAdd,
  MdPrecisionManufacturing,
  MdQrCodeScanner,
  MdRequestQuote,
  MdSchool,
  MdScience,
  MdSearch,
  MdSecurity,
  MdSettings,
  MdShoppingCart,
  MdWallet,
  MdWaterDrop,
} from "react-icons/md";

import { AuthAttribute, pickRoleAttrs } from "@megaron/access-control";
import { CustomerCategory } from "@megaron/crm-contracts";

import { LoyaltyAdminLogo } from "./LoyaltyAdminLogo";

export const visibleCustomersTableColumnsKey = ["all", "loyalty", "pos", "payers", "distributors", "chains"] as const;

export type VisibleCustomersTableColumnsKey = (typeof visibleCustomersTableColumnsKey)[number];

export const getPosCustomersUrl = (category?: CustomerCategory) => {
  const params = {
    page: 0,
    searchText: "",
    isPro: false,
    isAffiliate: false,
    status: ["unverified", "verified", "vip"],
    categories: [category ?? "pos"],
  };

  const searchText = JSON.stringify(params);

  return `/customers/new-list?filters=${encodeURIComponent(searchText)}`;
};

export type LinkIcon =
  | IconType
  | React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;

export type AppLinkTree = {
  app: string;
  name: string;
  Icon: LinkIcon;
  baseUrl: string;
  color: string;
  pageLinks: PageLink[];
  appLinkUrl?: string;
  hideAppLink?: boolean;
};

export type PageLink = {
  url: string;
  name: string;
  authorizedAttributes: AuthAttribute[];
  Icon: LinkIcon;
  isActive?: (pathname: string, searchParams: URLSearchParams) => boolean;
};

const crm: AppLinkTree = {
  name: "CRM",
  app: "crm",
  baseUrl: "/crm",
  color: "hsl(273, 75%, 25%)",
  Icon: MdGroups,
  pageLinks: [
    {
      name: "Klienci",
      url: "/customers",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdPerson,
      isActive: (pathname) => {
        const isActive =
          pathname.startsWith("/crm/customers") &&
          !pathname.startsWith("/crm/customers/new/") &&
          pathname !== "/crm/customers/new";
        return !!isActive;
      },
    },
    {
      name: "Dodaj klienta",
      url: "/customers/new",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdPersonAdd,
      isActive: (pathname) => {
        return pathname === "/crm/customers/new" || pathname.startsWith("/crm/customers/new/");
      },
    },
    {
      name: "Regiony",
      url: "/regions",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor"),
      Icon: MdMap,
    },
    {
      name: "Strona główna",
      url: "/user-home",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdOutlineHome,
    },
    {
      name: "Moje zamówienia",
      url: "/pos/moje_zamowienia",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdShoppingCart,
    },
    {
      name: "Zamówienia",
      url: "/orders",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdPallet,
    },

    {
      name: "Moje wizyty",
      url: "/pos/moje_wizyty",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdCalendarMonth,
    },
    {
      name: "Handlowcy",
      url: "/pos/handlowcy",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor"),
      Icon: MdBadge,
    },
    {
      name: "Trasy handlowe",
      url: "/pos/trasy",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdDirections,
    },
    {
      name: "Moje ankiety cenowe",
      url: "/pos/moje_ankiety_cenowe",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdAssignment,
    },
    {
      name: "Produkty",
      url: "/items",
      authorizedAttributes: pickRoleAttrs(
        "crm.admin",
        "crm.pricing-user",
        "crm.price-list-manager",
        "crm.user",
        "crm.supervisor",
      ),
      Icon: AiOutlineBarcode,
    },
    {
      name: "Cenniki",
      url: "/pos/cenniki",
      authorizedAttributes: pickRoleAttrs(
        "crm.admin",
        "crm.pricing-user",
        "crm.price-list-manager",
        "crm.user",
        "crm.supervisor",
      ),
      Icon: MdAttachMoney,
    },
    {
      name: "Ankiety cenowe",
      url: "/pos/ankiety_cenowe/produkty",
      authorizedAttributes: pickRoleAttrs("crm.admin"),
      Icon: MdOutlineChecklist,
    },

    {
      name: "Rabaty posprzedażowe",
      url: "/post-sale-discounts",
      authorizedAttributes: pickRoleAttrs("crm.admin"),
      Icon: MdOutlineDiscount,
    },
    {
      name: "Plan marży",
      url: "/profit-targets",
      authorizedAttributes: pickRoleAttrs("crm.admin"),
      Icon: MdFormatListBulleted,
    },
    {
      name: "Realizacja planu marży",
      url: "/region-profit-metrics",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdOutlineTrendingUp,
    },
    {
      name: "Moje zaproszenie",
      url: "/my-affiliate-codes",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdPersonAdd,
    },
    {
      name: "Dostępność produktów",
      url: "/product-availability",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdOutlineProductionQuantityLimits,
    },
    {
      name: "Szkolenia",
      url: "/training",
      authorizedAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
      Icon: MdSchool,
    },
  ],
};

const loyaltyAdmin: AppLinkTree = {
  name: "Specjalista",
  app: "loyaltyAdmin",
  baseUrl: "/specjalista",
  color: "hsl(328, 75%, 25%)",
  Icon: LoyaltyAdminLogo,
  pageLinks: [
    {
      name: "Linie produkcyjne",
      url: "/qr-sync",
      authorizedAttributes: pickRoleAttrs("loyalty-qr-sync.*"),
      Icon: MdPrecisionManufacturing,
    },
    {
      name: "Pokrywki",
      url: "/batches",
      authorizedAttributes: pickRoleAttrs("loyalty-points.admin", "loyalty-points.code-group-user"),
      Icon: MdQrCodeScanner,
    },
    {
      name: "Grupy kodów",
      url: "/product-codes/groups",
      authorizedAttributes: pickRoleAttrs("loyalty-points.*"),
      Icon: MdChecklist,
    },
    {
      name: "Sprawdź kod",
      url: "/product-codes/inspect-code",
      authorizedAttributes: pickRoleAttrs("loyalty-points.*", "crm.*"),
      Icon: MdSearch,
    },
    {
      name: "Realizacja nagród",
      url: "/orders",
      authorizedAttributes: pickRoleAttrs("loyalty-orders.*"),
      Icon: MdLocalShipping,
    },
    {
      name: "Katalog nagród",
      url: "/listings",
      authorizedAttributes: pickRoleAttrs("loyalty-points.admin", "loyalty-points.viewer"),
      Icon: MdListAlt,
    },
  ],
};

const invoices: AppLinkTree = {
  name: "Faktury",
  app: "invoices",
  baseUrl: "/faktury",
  appLinkUrl: "/watki",
  color: "hsl(234, 75%, 25%)",
  Icon: MdRequestQuote,
  pageLinks: [
    {
      name: "Wersje robocze",
      url: "/wersje-robocze",
      authorizedAttributes: pickRoleAttrs("invoices.*"),
      Icon: MdEditDocument,
    },
    {
      name: "Moje faktury",
      url: "/watki/moje-faktury",
      authorizedAttributes: pickRoleAttrs("invoices.coordinator", "invoices.admin"),
      Icon: MdInbox,
      isActive: (pathname, searchParams) => {
        const isActive =
          pathname.startsWith("/faktury/watki") && searchParams.get("filters")?.includes(`"isMine":"true"`);
        return !!isActive;
      },
    },
    {
      name: "Faktury",
      url: "/watki",
      authorizedAttributes: pickRoleAttrs("invoices.*"),
      Icon: MdFolder,
      isActive: (pathname, searchParams) => {
        const isActive =
          pathname.startsWith("/faktury/watki") && !searchParams.get("filters")?.includes(`"isMine":"true"`);
        return !!isActive;
      },
    },
    {
      name: "Budżety",
      url: "/budzety",
      authorizedAttributes: pickRoleAttrs("invoices.coordinator", "invoices.admin"),
      Icon: MdWallet,
    },
  ],
};

const iam: AppLinkTree = {
  name: "IAM",
  app: "iam",
  baseUrl: "/iam",
  color: "hsl(191, 75%, 25%)",
  Icon: MdSecurity,
  pageLinks: [
    {
      name: "Użytkownicy",
      url: "/accounts",
      authorizedAttributes: pickRoleAttrs("iam.admin", "iam.users-admin"),
      Icon: MdPerson,
    },
    {
      name: "Stanowiska",
      url: "/groups",
      authorizedAttributes: pickRoleAttrs("iam.admin", "iam.users-admin"),
      Icon: MdGroups,
    },
  ],
};

const plm: AppLinkTree = {
  name: "PLM",
  app: "plm",
  baseUrl: "https://plm.megarax.net",
  color: "hsl(14, 75%, 25%)",
  Icon: MdScience,
  pageLinks: [
    {
      name: "Decyzje",
      url: "/home",
      authorizedAttributes: pickRoleAttrs("plm.*"),
      Icon: MdDescription,
    },
    {
      name: "Komponenty",
      url: "/plm/komponent",
      authorizedAttributes: pickRoleAttrs("plm.user(komponent)", "plm.manager"),
      Icon: MdWaterDrop,
    },
    {
      name: "Receptury",
      url: "/plm/receptura",
      authorizedAttributes: pickRoleAttrs("plm.user(receptura)", "plm.manager"),
      Icon: MdOutlineMode,
    },
    {
      name: "Produkty",
      url: "/plm/produkt",
      authorizedAttributes: pickRoleAttrs("plm.user(produkt)", "plm.manager"),
      Icon: AiOutlineExperiment,
    },
    {
      name: "Wyroby",
      url: "/plm/wyroby",
      authorizedAttributes: pickRoleAttrs("plm.user(wyrob)", "plm.manager"),
      Icon: AiOutlineBgColors,
    },
    {
      name: "Asortymenty",
      url: "/plm/asortyment",
      authorizedAttributes: pickRoleAttrs("plm.user(asortyment)", "plm.manager"),
      Icon: AiOutlineTag,
    },
    {
      name: "Receptury produkcyjne",
      url: "/plm/recepturaProdukcyjna",
      authorizedAttributes: pickRoleAttrs("plm.user(productionRecipe)", "plm.manager"),
      Icon: MdFactory,
    },
  ],
};

const dev: AppLinkTree = {
  name: "DEV",
  app: "dev",
  baseUrl: "/dev",
  color: "hsl(205, 75%, 25%)",
  Icon: MdOutlineComputer,
  pageLinks: [
    {
      name: "Environment",
      url: "/environment",
      authorizedAttributes: pickRoleAttrs("dev.admin"),
      Icon: MdSettings,
    },
    {
      name: "Docs",
      url: "/docs",
      authorizedAttributes: pickRoleAttrs("dev.admin"),
      Icon: MdFolderCopy,
    },
  ],
};

const external: AppLinkTree = {
  name: "Pozostałe",
  app: "external",
  baseUrl: "",
  color: "#333",
  Icon: MdMoreHoriz,
  hideAppLink: true,
  pageLinks: [
    {
      name: "Plan urlopów",
      url: "https://docs.google.com/spreadsheets/d/1znffGlN6HXm58acA4lNY3cAKu0UZsTjUWZqUmKmnQhg/edit?gid=0#gid=0",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdEventBusy,
    },
    {
      name: "Szkolenia",
      url: "https://sites.google.com/megaron.com.pl/szkolenia/",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdSchool,
    },
    {
      name: "Shoper",
      url: "https://sklep712495.shoparena.pl/admin/",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdLink,
    },
    {
      name: "Way2Send (W2S)",
      url: "https://w2s.way2send.pl/login.aspx?ReturnUrl=%2fdefault.aspx",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdLink,
    },
    {
      name: "Kibana",
      url: "http://kibana.megarax.net",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdLink,
    },
    {
      name: "Business Central (BC)",
      url: "https://businesscentral.dynamics.com/Megaron",
      authorizedAttributes: ["userType:megarax"],
      Icon: MdLink,
    },
  ],
};

export const appLinkTrees = [invoices, crm, loyaltyAdmin, plm, iam, external, dev];
export const appLinkTreesObject = { invoices, crm, loyaltyAdmin, plm, iam, external, dev };
